<template>
  <!--Login -->
  <div class="loginDiv">
    <div class="container animate__animated animate__fadeInRight">
      <div class="base-dialog" v-if="!!error">
        <p>Dicka shkoi keq!</p>
        <p>{{ error }}</p>
      </div>

      <div class="base-dialog" v-if="!isLoading">
        <p>Ju lutem prisni!</p>
      </div>

      <!-- <div class="divComming">
        <h1>N'Llogaria is cooming soon...</h1>
      </div> -->

      <div class="mainBody" >
        <div class="headerImg">
          <div class="image">
            <img src="../../assets/Images/npost-logo-white.png" alt="" />
          </div>

          <div class="headerTxt">
            <h1>Indetifikohu për përdorimin e shërbimeve tona</h1>
            <hr />
          </div>
        </div>

        <div class="forma">
          <form @submit.prevent="submitForm">
            <div class="row">
              <div class="col col-6">
                <div class="inputs">
                  <input
                    type="email"
                    name="phone"
                    placeholder="Email"
                    v-model.trim="email"
                  />
                </div>
              </div>

              <div class="col col-6">
                <div class="inputs">
                  <input
                    type="password"
                    name="phone"
                    placeholder="Fjalëkalimi"
                    v-model.trim="password"
                  />
                </div>
              </div>

              <div class="col col-12">
                <div class="inputs dFlex">
                  <button id="nm">
                    <i class="fas fa-sign-in-alt"></i> {{ submitButtonCaption }}
                  </button>
                  <button @click="switchAuthMode()">
                    <i class="fas fa-sign-in-alt"></i>
                    {{ switchModeButtonCaption }}
                  </button>
                </div>
              </div>

              <div class="col col-12" v-if="!formIsValid">
                <div class="ifnotValid">
                  <p>
                    Ju lutemi vendosni numrin kontaktues dhe passwordin e saktë!
                    ( Fjalëkalimi duhet të jet së paku 6 karaktere )
                  </p>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <!-- Login End -->
</template>

<script>
export default {
  data() {
    return {
      isOfficial: false,
      email: "",
      password: "",
      formIsValid: true,
      mode: "login",
      isLoading: true,
      error: null,
    };
  },
  mounted(){
    // console.log(this.$apiUrl)
  },
  computed: {
    submitButtonCaption() {
      if (this.mode === "login") {
        return "Kycu";
      } else {
        return "Regjistrohu";
      }
    },
    switchModeButtonCaption() {
      if (this.mode === "login") {
        return "Dua të regjistrohem";
      } else {
        return "Dua të Kycem";
      }
    },
  },
  methods: {
    async submitForm() {
      this.formIsValid = true;
      if (
        this.email === "" ||
        !this.email.includes("@") ||
        this.password.length < 6
      ) {
        this.formIsValid = false;
        return;
      }
      this.isLoading = true;

      const actionPayload = {
        email: this.email,
        password: this.password,
      };

      try {
        if (this.mode === "login") {
          await this.$store.dispatch("login", actionPayload);
        } else {
          await this.$store.dispatch("signup", actionPayload);
        }
      } catch (err) {
        this.error = err.message || "Authentikimi dështoi, provoni më vonë!";
      }
      this.$router.replace("/");

      this.isLoading = false;
    },
    switchAuthMode() {
      if (this.mode === "login") {
        this.mode = "signup";
      } else {
        this.mode = "login";
      }
    },
    handleError() {
      this.error = null;
    },
  },
};
</script>
